import { AxiosResponse } from 'axios';
import fetch from 'services/request';
import { ICategoryResItem, IServiceResItem } from 'types/response';
import { IPayloadBooking } from '../../types/request';

const getCategories = (): Promise<AxiosResponse<{ payload: ICategoryResItem[] }>> => {
  return fetch({
    url: 'api/v1/service/category/list',
  });
};

const getServices = (): Promise<AxiosResponse<{ payload: IServiceResItem[] }>> => {
  return fetch({
    url: 'api/v1/service/list',
  });
};
const getStaffs = () => {
  return fetch({
    url: 'api/v1/staff/list',
  });
};

const bookAppointment = (body: IPayloadBooking) => {
  return fetch({
    url: 'api/v1/appointment/v2/bookingAppointment',
    method: 'post',
    body,
  });
};

const getTimeSlot = (currentDate: string, staffId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/appointment/getAvailableTimeSlotBookOnline/',
    params: { currentDate, staffId },
  });
};

const getShopDetail = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/shop/detail',
    shopId,
  });
};

const getCoupons = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/coupon/listByType?type=BOOKING',
  });
};

const getSettingDeposit = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/appointment/setting-deposit',
  });
};

const getPromotions = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/appointment/promotions',
  });
};

const getSalonGallery = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/gallery/all',
    params: { status: 'APPROVED' },
  });
};

const apis = {
  getCategories,
  getServices,
  getStaffs,
  bookAppointment,
  getTimeSlot,
  getShopDetail,
  getCoupons,
  getSettingDeposit,
  getPromotions,
  getSalonGallery,
};

export default apis;
