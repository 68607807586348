import aptServiceReducer from 'features/Booking/services/reducer';
import couponServiceReducer from 'features/Coupon/services/reducers';
import shoppingServiceReducer from 'features/ShoppingDeals/services/reducers';
import receiptServiceReducer from 'features/receipt/services/reducers';
import uiServiceReducer from 'services/UI/reducer';
import shopReducer from 'services/shop/reducers';

const rootReducer = {
  ui: uiServiceReducer,
  shop: shopReducer,
  appointment: aptServiceReducer,
  shopping: shoppingServiceReducer,
  coupon: couponServiceReducer,
  receipt: receiptServiceReducer,
};

export default rootReducer;
