import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import store from 'store';
import PageLoading from 'components/PageLoading';
import { mediaModalReviewRef } from 'features/Booking/v2/SalonReviews/MediaModaReview';

// Lazy load các pages
const NotFoundPage = lazy(() => import('NotFound'));
const ShopWrapperLayout = lazy(() => import('ShopWrapperLayout'));
const BookingPageV2 = lazy(() => import('features/Booking/v2/pages/BookingPage'));
const BookingSuccessPage = lazy(() => import('features/Booking/v2/pages/SuccessPage'));
const BookingSuccess = lazy(() => import('features/Booking/BookingSuccess'));
const BookingPageReview = lazy(() => import('features/Booking/v2/pages/ReviewPage'));
const BookingTransactionDetailV2 = lazy(() => import('features/Booking/v2/pages/TransactionDetail'));
const BookingPage = lazy(() => import('features/Booking/BookingPage'));
const BookingTransactionDetail = lazy(() => import('features/Booking/TransactionDetail'));
const ShoppingDeals = lazy(() => import('features/ShoppingDeals'));
const ProductDetailPage = lazy(() => import('features/ShoppingDeals/ProductDetail'));
const CheckOutPage = lazy(() => import('features/ShoppingDeals/CheckOut'));
const CustomerInfoPage = lazy(() => import('features/ShoppingDeals/CustomerInfo'));
const AddAddressPage = lazy(() => import('features/ShoppingDeals/AddAddress'));
const ShoppingLayout = lazy(() => import('features/ShoppingDeals/ShoppingLayout'));
const ShoppingTransactionDetailPage = lazy(() => import('features/ShoppingDeals/TransactionDetail'));
const CouponDetailPage = lazy(() => import('features/Coupon/CouponDetail'));
const SalonReviewsPage = lazy(() => import('features/Booking/v2/SalonReviews'));
const MediaModalReview = lazy(() => import('features/Booking/v2/SalonReviews/MediaModaReview'));
const ReceiptPage = lazy(() => import('features/receipt'));

const AppLayout: React.FC = () => {
  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        <Route path='/:shop_id' element={<ShopWrapperLayout />} >
          <Route path='' element={<BookingPage />} />
          <Route path='success' element={<BookingSuccess />} />
          <Route path='payment-detail/:transaction_id' element={<BookingTransactionDetail />} />
          <Route path='coupon/:coupon_id' element={<CouponDetailPage />} />
          <Route path="bill/:bill_id" element={<ReceiptPage />} />
        </Route>
        <Route path='/v2/:shop_id' element={<ShopWrapperLayout />} >
          <Route path='salon-reviews' element={<SalonReviewsPage />} />
          <Route path='' element={<BookingPageV2 />} />
          <Route path='review' element={<BookingPageReview />} />
          <Route path='success' element={<BookingSuccessPage />} />
          <Route path='payment-detail/:transaction_id' element={<BookingTransactionDetailV2 />} />
        </Route>
        <Route path='/:shop_id/shopping' element={<ShoppingLayout />}>
          <Route path='' element={<ShoppingDeals />} />
          <Route path=':id' element={<ProductDetailPage />} />
          <Route path='customer-info' element={<CustomerInfoPage />} />
          <Route path='check-out' element={<CheckOutPage />} />
          <Route path='add-address' element={<AddAddressPage />} />
          <Route path='payment-detail/:transaction_id' element={<ShoppingTransactionDetailPage />} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};

const App: React.FC = () => (
  <HelmetProvider>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F05326',
          colorPrimaryBg: '#FFF1F6',
          colorPrimaryActive: '#F05326',
          colorText: '#1D2129',
          colorTextSecondary: '#767676',
          colorBorder: '#86909C',
          colorBorderSecondary: '#CECECE',
          colorFill: '#E5E6EB',
          colorFillSecondary: '#E5E5E5',
          colorFillTertiary: '#F6F7FC',
          colorBgLayout: '#E5E5E5',
          colorBgSpotlight: '#1D2129',
          colorBgMask: 'rgba(0, 0, 0, 0.2)',
          colorTextBase: '#1d2129',
          colorBgBase: '#ffffff',
          colorWarning: '#ff7d00',
          colorError: '#f53f3f',
          colorInfo: '#6fabb6',
          fontSize: 16,
          sizeStep: 4,
          borderRadius: 5,
          borderRadiusXS: 2,
          wireframe: false
        },
      }}
    >
      <Provider store={store}>
        <AppLayout />
        <ToastContainer />
        <PageLoading />
        <MediaModalReview ref={mediaModalReviewRef} />
      </Provider>
    </ConfigProvider>
  </HelmetProvider>
);

export default App;
