
import { AxiosResponse } from 'axios';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';
import { IInfoPrintBillResData } from './types/bill';

const getInfoPrintBill: ISagaFunc<string> = function* ({ payload }) {
  yield delay(200);
  try {
    const res: AxiosResponse<{ payload: IInfoPrintBillResData }> = yield call(apis.getInfoPrintBill, payload);
    if (res.data.payload) {
      yield put(actions.getInfoPrintBill.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getInfoPrintBill.fail({}));
  }
};

export default function* receiptServiceSagas() {
  yield takeLatest(actions.getInfoPrintBill.fetch, getInfoPrintBill);
}
