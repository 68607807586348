import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiGetDemoDataParam } from './types/api';

const getDemoData = createAsyncAction<IApiGetDemoDataParam>(PREFIX_ACTIONS + 'getDemoData');

const getInfoPrintBill = createAsyncAction<string>(PREFIX_ACTIONS + 'getInfoPrintBill');
const receiptActions = {
  getDemoData,
  getInfoPrintBill,
};

export default receiptActions;
