import fetch from 'services/request';

const getInfoPrintBill = (id: string) => {
  return fetch({
    url: `/api/v3/bill/getInfoPrintBill?billId=${id}`,
    method: 'get',
  });
};

const couponApis = {
  getInfoPrintBill,
};

export default couponApis;
