import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  infoPrintBill: {
    loading: true,
    data: null,
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getInfoPrintBill.fetch, (state) => {
        state.infoPrintBill.loading = true;
        state.infoPrintBill.data = null;
      })
      .addCase(actions.getInfoPrintBill.success, (state, { payload }) => {
        state.infoPrintBill.loading = false;
        state.infoPrintBill.data = payload;
      })
      .addCase(actions.getInfoPrintBill.fail, (state) => {
        state.infoPrintBill.loading = false;
        state.infoPrintBill.data = null;
      })
      ;
  },
});

export const receiptUIActions = Slice.actions;
const receiptServiceReducer = Slice.reducer;
export default receiptServiceReducer;
